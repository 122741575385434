import React from 'react'
import Layout from "../components/layout"


export default function error() {
    return (
     
        <Layout>
        <h1>This is error page</h1>
        </Layout>
        
    )
}
